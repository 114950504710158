q_style.writeStyle("qrf_endstudyreport",'.endstudy_report', {
    position:"absolute",
    top:"221px",
    left:"190px",
    width:"1540px",
    height:"650px",
    "background-color":"#FFE9C3",
    "box-shadow": "0px 10px 16px 0px rgba(49, 89, 27, 0.2)",
    "border-radius": "24px",
    ".close_img":{
        position:"absolute",
        top:"52px",
        right:"64px",
        width:"44px",
        height:"44px",
        "img":{
            width:"100%",
            height:"100%",
        }
    },
    ".endstudy_title":{
        position:"absolute",
        top:"52px",
        left:"713px",
        width:"auto",
        height:"40px",
        "text-align":"center","line-height":"40px",
        "font-size":"36px","font-family":"Roboto","font-weight":"bold",color:"#572C1C",
    },
    ".endstudy_bar":{
        position:"absolute",
        top:"122px",
        left:"64px",
        width:"1412px",
        height:"1px",
        "background-color":"#572D1C",
        opacity: "0.2",
    },
    ".endstudy_content":{
        position:"absolute",
        top:"175px",
        left:"170px",
        width:"1200px",
        height:"auto",
        "text-align":"center","line-height":"38px",
        "font-size":"32px","font-family":"Roboto","font-weight":"bold",color:"#572D1C",
    },
    ".endstudy_body":{
        position:"absolute",
        "background-color": "#FFE9C3",
        top:"285px",
        left:"126px",
        width:"1288px",
        height:"306px",
        "border-radius":"12px",
        "background": "#562C1C",
        "img":{
            position:"absolute",
            top:"64px",left:"612px",width:"64px",height:"64px",
        },
        ".content":{
            position:"absolute",
            left:"0",
            width:"100%",
            height:"38px",
            "text-align":"center","line-height":"38px",
            "font-size":"32px","font-family":"Roboto","font-weight":"bold",color:"#FFFFFF",
        },
    },
}).apply("qrf_endstudyreport");