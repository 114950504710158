q_style.writeStyle("qrf_report",'.timeout_report', {
    position:"absolute",
    top:"299px",
    left:"500px",
    width:"920px",
    height:"482px",
    ".timeout_body":{
        "background-color": "#FFE9C3",
        top:"0",
        left:"0",
        width:"920px",
        height:"369px",
        border:"1px solid transparent",
        "border-radius":"24px",
        "box-shadow": "0px 10px 16px 0px rgba(89, 55, 27, 0.2)",
        ".report_img":{
            position:"absolute",
            top:"32px",
            left:"370px",
            width:"180px",
            height:"180px",
            "img":{
                width:"100%",
                height:"100%",
            }
        },
        ".content":{
            position:"absolute",
            left:"0",
            width:"920px",
            height:"38px",
            "text-align":"center","line-height":"38px",
            "font-size":"32px","font-family":"Roboto","font-weight":"bold",color:"#572C1C",
        },
    },
    ".no_but":{
        position:"absolute",
        top:"393px",
        left:"88px",
        width:"340px",
        height:"88px",
        "border-radius": "12px",
        border: "1px solid #572C1C",
        background:"#FFFFFF",
        "text-align":"center","line-height":"88px",
        "font-size":"28px","font-family":"Roboto","font-weight":"bold",color:"#572D1C",
    },
    ".yes_but":{
        position:"absolute",
        top:"393px",
        right:"88px",
        width:"340px",
        height:"88px",
        "border-radius": "12px",
        "box-shadow": "0px 10px 16px 0px rgba(89, 55, 27, 0.2)",
        background:"#562C1C",
        "text-align":"center","line-height":"88px",
        "font-size":"28px","font-family":"Roboto","font-weight":"bold",color:"#F7B500",
    },
}).apply("qrf_report");