let qrfDom = require("./include/qrfDom");
let http = require("./include/ContextConf");
let studentStatus = require("../common/studentStatus");
qrfDom.vars.show="";
require("./card");
require("./invite");
require("./endStudyReport");
require("./fileReport");
require("./timeoutReport");
require("./toast");

qrfDom.addVars({
});
qrfDom.addEves({
    setCacheSelf:(status)=>{
        let options = {
            cache:true,
            cacheName:"getSelfInfo",
            cacheType:"local"
        };
        let cache_self =  http.cache_z.read(options);
        if(cache_self && typeof status != 'undefined'){
            if(cache_self.body){
                cache_self.body.status = status;
                http.cache_z.write(options,cache_self.body);
                // let cache_self1 =  http.cache_z.read(options);
            }
        }//设置缓存，这样刷新的时候可以先使用缓存数据
    },
    endStudy:()=>{/*在首页调起的学习终止*/
        console.log("enter report endStudy");
        //处理学习信息
        var studyInfo = q_.getLocalStorage("studyInfo");
        if(!studyInfo){
            console.log("studyInfo is null.");
            return;
        }
        let status = studyInfo.status;
        var exitTime = q_.getLocalStorage("exitTime");
        if(!exitTime){
            if(status != studentStatus.idle){
                exitTime = studyInfo.endStudyTime;
            }
            else{
                var outAppTime = q_.getLocalStorage("outAppTime");
                if(outAppTime){
                    exitTime=outAppTime;
                }
                else{
                    exitTime = q_U.getDateUTC();
                }
            }
        }
        var nowStr = q_U.getDateUTC();
        var lastTime = new Date(exitTime);
        var nowTime = new Date(nowStr);
        console.log(lastTime,nowTime);
        if(nowTime < lastTime && studyInfo.status != studentStatus.idle){//时间有问题，直接结束学习
            console.log(nowTime,lastTime);
            //发送学习结束指令
            http.post("endPlan",{
                data:{
                    endStudyTime:nowStr,
                    mode:studyInfo.mode,
                    status:studyInfo.status,
                    startStudyTime:studyInfo.startStudyTime,
                    thisRestStartTime:studyInfo.thisRestStartTime,
                    thisStudyStartTime:studyInfo.thisStudyStartTime,
                    thisTotalStudyTime:studyInfo.thisTotalStudyTime,
                    thisRealStudyTime:studyInfo.thisRealStudyTime,
                    thisTotalRestTime:studyInfo.thisTotalRestTime,
                }
            });
            return;
        }

        if(status != studentStatus.idle){
            //发送学习结束指令
            http.post("endPlan",{
                data:{
                    mode:studyInfo.mode,
                    endStudyTime:exitTime,
                    startStudyTime:studyInfo.startStudyTime,
                    thisRestStartTime:studyInfo.thisRestStartTime,
                    thisStudyStartTime:studyInfo.thisStudyStartTime,
                    thisTotalStudyTime:studyInfo.thisTotalStudyTime,
                    thisRealStudyTime:studyInfo.thisRealStudyTime,
                    thisTotalRestTime:studyInfo.thisTotalRestTime,
                }
            });
        }
    },
});
if(!qrfDom.public)qrfDom.public={};
if(!qrfDom.public.eves)qrfDom.public.eves={};
qrfDom.public.eves.endStudy=qrfDom.eves.endStudy;

var html = `
<div>
<comp-cardbody></comp-cardbody>
<comp-invitebody></comp-invitebody>
<comp-endstudyreportbody></comp-endstudyreportbody>
<comp-filereportbody></comp-filereportbody>
<comp-timeoutreportbody></comp-timeoutreportbody>
<comp-toastbody></comp-toastbody>
</div>`;
qrfDom.render(html);
console.log(qrfDom.vars);
// qrfDom.set("show","invite");
q_.registEvent("showReportBox",function(name,param){
    console.log("enter showReportBox",name,param);
    console.log("report:::"+param.type);
    if(param.type == 'card'){
        qrfDom.set("show","card");
        qrfDom.eves.initCard();
    }
    else if(param.type == 'invite'){
        qrfDom.set("show","invite");
        qrfDom.eves.initInvite();
    }
    else if(param.type == 'exit'){
        qrfDom.set("show","endStudy");
        qrfDom.eves.initEndStudyReport("exit",param.thisTotalStudyTime,param.thisRealStudyTime,param.thisTotalRestTime,param.thisTotalCoins);
    }
    else if(param.type == 'timeout'){
        qrfDom.set("show","endStudy");
        qrfDom.eves.initEndStudyReport("timeout",param.thisTotalStudyTime,param.thisRealStudyTime,param.thisTotalRestTime,param.thisTotalCoins);
    }
    else if(param.type == 'pause'){
        qrfDom.set("show","endStudy");
        qrfDom.eves.initEndStudyReport("pause",param.thisTotalStudyTime,param.thisRealStudyTime,param.thisTotalRestTime,param.thisTotalCoins);
    }
    else if(param.type == 'file'){
        console.log("enter file report");
        qrfDom.set("show","fileReport");
        qrfDom.eves.initFilereport(param.fileNo);
    }
    else if(param.type == 'break'){
        qrfDom.set("show","timeoutReport");
        qrfDom.eves.initTimeoutreport(param);
    }
    else if(param.type == 'toast'){
        if(param.close){
            qrfDom.eves.closeToast();
        }
        else{
            qrfDom.eves.toast(param.content,param.time);
        }
    }
});