let qrfDom = require("./include/qrfDom");
let http = require("./include/ContextConf");
require("./css/cardCss");
let studentStatus = require("../common/studentStatus");
qrfDom.addVars({
    cardActive:"",
    usedTime:"",
    user_in_card:null,
    goodName:"",
});
qrfDom.addEves({
    initCard:()=>{
        http.post("getStudentInfo",{
            success:(qDom,data,conf)=>{
                console.log("card getStudentInfo",data);
                if(data.header == null){
                    q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_.notisfyEvent("showMessageBox",{title:"error",content:"back error："+msg});
                        throw data.header.msg;
                    }
                }
                if(null != data.body){//没有登记过信息
                    qrfDom.eves.initCardSucc(data.body);
                }
            },
            error:(err)=>{
                q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                throw err;
            }
        });
    },
    initCardSucc:(self)=> {
        qrfDom.set("user_in_card",self);
        let cardActive = self.active;
        let activeStartTime = self.activeStartTime;
        let activeEndTime = self.activeEndTime;
        let nowStr = q_U.getDateUTC();
        let usedTime = activeEndTime;
        if(cardActive && activeEndTime != null && new Date(activeEndTime) > new Date(nowStr)){
            let dTime = new Date(usedTime) - new Date(nowStr);
            console.log(usedTime);
            console.log(nowStr);
            dTime = dTime / 1000 / 60;
            console.log(dTime);
            let days = parseInt(dTime / 24 /60);
            console.log(days);
            let hours = parseInt((dTime - days * 24 * 60) / 60);
            console.log(hours);
            let mins = parseInt(dTime % 60);
            console.log(mins);
            qrfDom.set("cardActive", cardActive);
            qrfDom.set("usedTime", days+"days "+hours+"hours "+mins+"min");
        }
        else{
            qrfDom.set("cardActive","");
        }
    },
    closeCard:()=>{
        qrfDom.set("show","");
    },
    gotoInviteFriend:function(){
        qrfDom.set("show","invite");
        qrfDom.eves.initInvite();
    },
    buy:(active,e)=>{
        let cardActive = qrfDom.getVal("cardActive");
        if('week' == cardActive){
            q_Toast("You have got a card,effective for 7 days",2000);
            return;
        }
        else if('month' == cardActive){
            q_Toast("You have got a card to a continuous monthly plan",2000);
            return;
        }
        if(active=='week'){
            qrfDom.set("goodName","weekCard");
        }
        else if(active=="month"){
            qrfDom.set("goodName","monthCard");
        }
        http.post("addCardOrder");
    },
    googlePay:(orderInfo)=>{
        let user = qrfDom.getVal("user_in_card");
        orderInfo.userId = user.userId;
        console.log("orderInfo:::"+JSON.stringify(orderInfo));
        let goodName = orderInfo.goodName;
        if(window.native){
            if(goodName=='weekCard'){
                orderInfo.active='week';
                window.native.bootGooglePay(JSON.stringify(orderInfo));
            }
            else if(goodName=="monthCard"){
                orderInfo.active='month';
                window.native.bootGooglePay(JSON.stringify(orderInfo));
            }
        }
        else if(isTest){
            if(goodName=='weekCard'){
                orderInfo.active='week';
                buyCardSucc(orderInfo);
            }
            else if(goodName=="monthCard"){
                orderInfo.active='month';
                buyCardSucc(orderInfo);
            }
        }
    },
});
if(!qrfDom.public)qrfDom.public={};
if(!qrfDom.public.eves)qrfDom.public.eves={};
qrfDom.public.eves.buyCardSucc=(params)=>{
    console.log("enter public.eves.buyCardSucc:"+params);
    //后台通讯
    if(typeof params == 'string'){
        params = JSON.parse(params);
    }
    var goodName = params.goodName;
    if(goodName=='weekCard'){
        params.active='week';
    }
    else if(goodName=='monthCard'){
        params.active='month';
    }
    q_.call("#q_loading","show",{});
    http.post("buyCard",{
        data:params
    });
}
qrfDom.eves.setCardActiveSucc=(params)=>{
    let active = params.active;
    if(active){
        q_Toast("You have bought the card to increase the speed of obtaining coins for 3 times.",2000);
        qrfDom.set("cardActive",active);
        let days = 7;
        if(active=='month'){
            let d = new Date();
            let usedTime = new Date(d.setMonth(d.getMonth() + 1));
            console.log(usedTime);
            let dTime = usedTime - new Date();
            dTime = dTime / 1000 / 60;
            let days = parseInt(dTime / 24 /60);
            qrfDom.set("usedTime", days+" days 23hours 59min");
        }
        else if(active=='week'){
            qrfDom.set("usedTime", 7+" days 0hours 0min");
        }
    }
}

qrfDom.definedComp("card",`
<div class="card">
    <div class="close_controller" qrf-click="closeCard">
        <img src="./module/common/images/common/close-icon@3x.png" />
    </div>
    <div class="card_label">
        To accerate coin rewards
    </div>
    <div class="bar_controller" style="top:122px;"></div>
    <div class="card_body week">
        <div class="text_title"><div class="card_desc">7-day trial</div><div qrf-if="cardActive=='week'" class="card_active">Working</div></div>
        <div class="text_content">
            <div class="more1">The speed of obtaining coins will be increased by 3 times,effective for 7 days.</div>
            <div class="more2">
                <div class="left" qrf-style="cardActive=='week'?'':'display:none;'">Validity period</div>
                <div class="money" qrf-style="cardActive=='week'?'display:none;':''">$ 1.99</div>
                <div class="right" qrf-style="cardActive=='week'?'':'display:none;'" qrf-text="usedTime"></div>
                <div class="card_but" qrf-style="cardActive=='week'?'display:none;':''" qrf-click="buy.bind(null,'week')">Buy</div>
            </div>
        </div>
    </div>
    <div class="card_body month">
        <div class="text_title"><div class="card_desc">Monthly plan</div><div qrf-if="cardActive=='month'" class="card_active">Working</div></div>
        <div class="text_content">
            <div class="more1">The speed of obtaining coins will be increased by 3 times.Subscribe to a continuous monthly plan.</div>
            <div class="more2">
                <div class="left" qrf-style="cardActive=='month'?'':'display:none;'">Validity period</div>
                <div class="money" qrf-style="cardActive=='month'?'display:none':';'">$ 2.99</div>
                <div class="right" qrf-style="cardActive=='month'?'':'display:none;'" qrf-text="usedTime"></div>
                <div class="card_but" qrf-style="cardActive=='month'?'display:none;':''" qrf-click="buy.bind(null,'month')">Buy</div>
            </div>
        </div>
    </div>
    <div class="bar_controller" style="bottom:152px;"></div>
    <div class="freecoins_controller" qrf-click="gotoInviteFriend">Get more free coins >></div>
</div>
`,{}
);

qrfDom.definedComp("cardbody",`
<div qrf-if="show=='card'" style="position:absolute;z-index:30;width:100%;height:100%;background: rgba(0,0,0,0.5);">
    <comp-card></comp-card>
</div>`);