let qrfDom = require("./include/qrfDom");
let http = require("./include/ContextConf");
let studentStatus = require("../common/studentStatus");
require("./css/timeoutReportCss");
qrfDom.addVars({
    "param":null,
    "thisTotalRestTime":0,
});
qrfDom.addEves({
    initTimeoutreport:(param)=>{
        console.log("enter initTimeoutreport",param);
        let restDuration = param.restDuration;
        let thisRestStartTime = param.thisRestStartTime;
        let startTime = new Date(thisRestStartTime);
        let nowUTCTime = q_U.getDateUTC(new Date());
        let nowTime = new Date(nowUTCTime);
        let leaveTime = (nowTime - startTime)/1000 - restDuration + (2 * 60);//加上两分钟
        if(leaveTime <= 0){
            param.quit();
        }
        qrfDom.eves.timeoutHdl = setTimeout(function(){
            if(qrfDom.eves.timeoutHdl){
                clearTimeout(qrfDom.eves.timeoutHdl);
                qrfDom.eves.timeoutHdl = null;
            }
            qrfDom.set("show","");
            param.quit();
        },leaveTime * 1000);
        qrfDom.set("thisTotalRestTime",parseInt(param.restDuration / 60));
        qrfDom.set("param",param);
    },
    closeTimeoutReport:()=>{//meybe later
        console.log("enter closeTimeoutReport");
        if(qrfDom.eves.timeoutHdl){
            clearTimeout(qrfDom.eves.timeoutHdl);
            qrfDom.eves.timeoutHdl = null;
        }
        qrfDom.set("show","");
        let param = qrfDom.getVal("param");
        param.no();
    },
    submitTimeoutReport:()=>{//马上学习
        console.log("enter submitTimeoutReport");
        if(qrfDom.eves.timeoutHdl){
            clearTimeout(qrfDom.eves.timeoutHdl);
            qrfDom.eves.timeoutHdl = null;
        }
        qrfDom.set("show","");
        let param = qrfDom.getVal("param");
        param.yes();
    },
});
qrfDom.definedComp("timeoutreport",`
<div class="timeout_report">
    <div class="timeout_body">
        <div class="report_img">
            <img src="./module/common/images/studyroom/restTimeout-report.png" />
        </div>
        <div class="content" style="top:228px">
            <span>You've been resting for </span><span qrf-text="thisTotalRestTime"></span><span> minutes. It's time to study.</span>
        </div>
    </div>
    <div class="no_but" qrf-click="closeTimeoutReport">Maybe later</div>
    <div class="yes_but" qrf-click="submitTimeoutReport">OK</div>
</div>
`,{}
);

qrfDom.definedComp("timeoutreportbody",`
<div qrf-if="show=='timeoutReport'" style="position:absolute;z-index:30;width:100%;height:100%;background: rgba(0,0,0,0.5);">
    <comp-timeoutreport></comp-timeoutreport>
</div>`);