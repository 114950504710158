q_style.writeStyle("qrf_card",'.card', {
    position:"absolute",
    top:"190px",
    left:"191px",
    width:"1538px",
    height:"750px",
    "z-index": "99",
    background:"#FFE9C3",
    "border-radius": "24px",
    ".close_controller":{
        position:"absolute",
        top:"52px",
        right:"64px",
        width:"44px",
        height:"44px",
        "img":{
            width:"100%",
            height:"100%",
        }
    },
    ".card_label":{
        position:"absolute",
        top:"52px",
        left:"559px",
        width:"auto",
        height:"40px",
        "line-height":"40px",
        "font-size":"36px","font-family":"Roboto","font-weight":"bold",color:"#572C1C",
    },
    ".bar_controller":{
        position:"absolute",
        left:"63px",
        width:"1412px",
        height:"1px",
        "background-color":"#572D1C",
        opacity: "0.2",
    },
    ".card_body":{
        position:"absolute",
        color:"#572C1C",
        top:"190px",
        width:"600px",
        height:"320px",
        "border-radius":"12px",
        border: "8px solid #E4836D",
        background:"#FFF9EE",
        ".text_title":{
            position:"absolute",
            left:"0",top:"0",
            width:"100%",height:"80px",
            "border-radius":"12px 12px 0px 0px","border-bottom": "8px solid #E4836D",
            ".card_desc":{
                position:"absolute",
                left:"24px",top:"24px",
                width:"auto",height:"38px",
                "font-size":"32px","font-family":"Roboto","font-weight":"bold",color:"#572C1C",
                "text-shadow": "0px 10px 16px rgba(220, 152, 80, 0.2)",
            },
            ".card_active":{
                position:"absolute",
                right:"0",top:"18px",
                width:"159px",height:"47px","border-radius": "8px 0px 0px 8px","text-align":"center","line-height":"47px",
                "font-size":"28px","font-family":"Roboto","font-weight":"bold",color:"#FFFFFF",
                background:"#FA6400","text-shadow": "0px 10px 16px rgba(220, 152, 80, 0.2)",
            }
        },
        ".text_content":{
            position:"absolute",
            left:"0",bottom:"0",
            width:"100%",height:"232px",
            "border-radius":"0px 0px 12px 12px",
            ".more1":{
                position:"absolute",
                left:"24px",top:"25px",
                width:"552px",height:"101px","line-height": "33px",
                "font-family":"Roboto","font-size":"28px","font-weight":"400",color:"#572C1C","text-shadow": "0px 10px 16px rgba(220, 152, 80, 0.2)",
            },
            ".more2":{
                ".left":{
                    position:"absolute",
                    left:"43px",bottom:"53px",
                    width:"auto",height:"33px","line-height": "33px",
                    "font-family":"Roboto","font-size":"24px","font-weight":"400",color:"#888888","text-shadow": "0px 10px 16px rgba(220, 152, 80, 0.2)",
                },
                ".money":{
                    position:"absolute",
                    left:"24px",bottom:"32px",
                    width:"auto",height:"47px","line-height": "47px",
                    "font-family":"Roboto","font-size":"40px","font-weight":"900",color:"#FA6400",
                },
                ".right":{
                    position:"absolute",
                    right:"48px",bottom:"49px",
                    width:"auto",height:"40px","line-height": "40px",
                    "font-family":"Roboto","font-size":"28px","font-weight":"400",color:"#000000","text-shadow": "0px 10px 16px rgba(220, 152, 80, 0.2)",
                }
            },
            ".card_but":{
                position:"absolute",
                right:"24px",bottom:"24px",
                width:"180px",height:"64px","line-height": "64px",background:"#562C1C","border-radius": "32px","text-align":"center",
                "font-family":"Roboto","font-size":"28px","font-weight":"bold",color:"#F7B500","text-shadow": "0px 10px 16px rgba(220, 152, 80, 0.2)",
            }
        }
    },
    ".week":{
        left:"116px",
    },
    ".month":{
        right:"126px",
    },
    ".freecoins_controller":{
        position: "absolute",
        bottom:"53px",
        left: "592px",
        height: "45px",
        width: "auto",color:"#FA6400",
        "font-family":"Roboto","font-size":"32px","font-weight":"bold","line-height":"45px",
    },
}).apply("qrf_card");