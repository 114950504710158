let qrfDom = require("./include/qrfDom");
let http = require("./include/ContextConf");
let studentStatus = require("../common/studentStatus");
require("./css/endStudyReportCss");
qrfDom.addVars({
    warnContent:"",
    thisTotalStr:"0 minutes",
    thisTimeDurination:0,
    thisTimeDurinationStr:"",
    thisTotalCoins:0,
});
qrfDom.addEves({
    initEndStudyReport:(type,thisTotalStudyTime,thisRealStudyTime,thisTotalRestTime,thisTotalCoins)=>{
        if(!thisTotalRestTime){thisTotalRestTime=0;}
        if(!thisTotalStudyTime){thisTotalStudyTime=0;}
        if(!thisTotalCoins){thisTotalCoins=0;}
        switch(type){
            case "exit":
                qrfDom.set("warnContent","You have achieved your goal, and you are one step closer to excellence.");
                break;
            case "timeout":
                qrfDom.set("warnContent","You didn't return to the classroom in time during the recess. More than 2 minutes late and your seat was withdrawn. Please come back on time next time.");
                break;
            case "pause":
                qrfDom.set("warnContent","You ended the study ahead of schedule. Hard work is the key to getting better.");
                break;
        }
        let thisTotalStr = parseInt(thisTotalRestTime / 60 + thisTotalStudyTime / 60)+" minutes";
        console.log("thisTotalStr:",thisTotalStr);
        qrfDom.set("thisTotalStr",thisTotalStr);
        let thisTimeDurinationStr = parseInt(thisRealStudyTime / 60)+" minutes";
        qrfDom.set("thisTimeDurinationStr",thisTimeDurinationStr);
        qrfDom.set("thisTotalCoins",thisTotalCoins);
        console.log(qrfDom.vars);
    },
    closeEndstudyReport:()=>{
        qrfDom.set("show",'');
        q_.refresh();
    },
});
qrfDom.definedComp("endstudyreport",`
<div class="endstudy_report">
    <div class="close_img" qrf-click="closeEndstudyReport">
        <img src="./module/common/images/studyroom/close-icon@3x.png" />
    </div>
    <div class="endstudy_title" >
        Report
    </div>
    <div class="endstudy_bar" >
    </div>
    <div class="endstudy_content" qrf-text="warnContent">
    </div>
    <div class="endstudy_body">
        <img src="./module/common/images/studyroom/report-icon@3x.png" />
        <div class="content" style="top:180px">
            <span>This self-study lasted </span><span style="color:#F7B500;" qrf-text="thisTotalStr"></span><span>, of which you focused for </span><span style="color:#F7B500;" qrf-text="thisTimeDurinationStr"><span>
        </div>
        <div class="content" style="top:218px">
              <span>and got </span><span style="color:#F7B500;" qrf-text="thisTotalCoins"></span><span> coins in total.</span>
        </div>
    </div>
</div>
`,{}
);

qrfDom.definedComp("endstudyreportbody",`
<div qrf-if="show=='endStudy'" style="position:absolute;z-index:30;width:100%;height:100%;background: rgba(0,0,0,0.5);">
    <comp-endstudyreport></comp-endstudyreport>
</div>`);