let qrfDom = require("./include/qrfDom");
let http = require("./include/ContextConf");
require("./css/inviteCss");
let studentStatus = require("../common/studentStatus");
qrfDom.addVars({
    inviteActive:"week",
    inviteCode:"",
    inviteTimes:0,
    inviteWarningShow:false,
    inviteWarningContent:"",
});
qrfDom.addEves({
    initInvite:()=>{
        qrfDom.preventShake("report_invite_init");
        http.post("getStudentInfo");
    },
    getStudentInfoSucc:(student)=>{
        let inviteCode = student.inviteCode;
        console.log("enter getStudentInfoSucc");
        if(inviteCode){
            console.log("inviteCode:"+inviteCode);
            qrfDom.set("inviteCode", inviteCode);
            if(typeof student.inviteTimes == 'number'){
                qrfDom.set("inviteTimes", 3-student.inviteTimes);
            }
            console.log("inviteTimes:",student.inviteTimes);
        }
        else{
            console.log("generate InviteCode");
            //生成一个邀请码
            http.post("generateInviteCode");
        }
    },
    closeinvite:()=>{
        console.log("enter closeinvite");
        qrfDom.set("show","");
    },
    selectText:(text)=>{
        if (document.body.createTextRange) {
            var range = document.body.createTextRange();
            range.moveToElementText(text);
            range.select();
        } else if (window.getSelection) {
            var selection = window.getSelection();
            var range = document.createRange();
            range.selectNodeContents(text);
            selection.removeAllRanges();
            selection.addRange(range);
            /*if(selection.setBaseAndExtent){
                selection.setBaseAndExtent(text, 0, text, 1);
            }*/
        } else {
            alert("none");
        }
    },
    copyInviteCode:()=>{
        let inviteCode = qrfDom.getVal("inviteCode");
        var inviteCodeEle = document.getElementById('invite_code');
        var currentFocus = document.activeElement;
        qrfDom.eves.selectText(inviteCodeEle);
        document.execCommand('copy', true);
        q_Toast("copied");
    },
});

qrfDom.definedComp("invite",`
<div class="invite">
    <div class="close_controller" qrf-click="closeinvite">
        <img src="./module/common/images/common/close-icon@3x.png" />
    </div>
    <div class="invite_label_bg">
    <div class="invite_label">
        Invite Friends
    </div></div>
    <div class="bar_controller" style="top:122px;"></div>
    <div class="invite_remark"><div><span>Invited </span><span qrf-text="inviteTimes"></span><span> Friends</div></span>
    </div>
    <div class="invite_title">Invite Friends and Earn Coins.
    </div>
    <div class="invite_content_bg">
    <div class="invite_content">Invite friends to Studyland.Once they register and enter your referral code,Both of you will get a friendship Reward of 1000 coins.
    </div></div>
    <div class="invite_code_controller"><div id="invite_code" class="invite_code" qrf-text="inviteCode"></div><div class="invite_code_copy" qrf-click="copyInviteCode"><img src="./module/common/images/common/copy-icon.png" /></div></div>
</div>
`,{}
);

qrfDom.definedComp("invitebody",`
<div qrf-if="show=='invite'" style="position:absolute;z-index:30;width:100%;height:100%;background: rgba(0,0,0,0.5);">
    <comp-invite></comp-invite>
</div>`);