q_style.writeStyle("qrf_invite",'.invite', {
    position:"absolute",
    top:"190px",
    left:"191px",
    width:"1538px",
    height:"750px",
    "z-index": "99",
    background:"#FFE9C3",
    "border-radius": "24px",
    ".close_controller":{
        position:"absolute",
        top:"52px",
        right:"64px",
        width:"44px",
        height:"44px",
        "z-index":"10",
        "img":{
            width:"100%",
            height:"100%",
        }
    },
    ".invite_label_bg":{
        position:"absolute",
        left:"0", top:"52px",width: "100%", "height": "40px",display:"flex","flex-direction":"column","justify-content":"center","align-items":"center",
        ".invite_label":{
            position:"relative",
            top:"0",
            left:"0",
            width:"auto",
            height:"40px",
            "line-height":"40px",
            "font-size":"36px","font-family":"Roboto","font-weight":"bold",color:"#572C1C",
        },
    },
    ".bar_controller":{
        position:"absolute",
        left:"63px",
        width:"1412px",
        height:"1px",
        "background-color":"#572D1C",
        opacity: "0.2",
    },
    ".invite_remark":{
        position:"absolute",display:"flex","flex-direction":"row","justify-content":"space-around","align-items":"center",
        top:"159px",
        left:"322px",
        width: "852px",
        "height": "140px",
        "background": "#713D2C",
        "border-radius": "24px",
        "border": "10px solid #F7B500","text-align":"center","line-height":"140px",
        "font-size":"36px","font-family":"Roboto","font-weight":"bold",color:"#FFFFFF",
        "div":{
            width:"auto",height:"auto",
        },
    },
    ".invite_title":{
        position:"absolute",
        top:"351px",
        left:"582px",
        width: "auto",
        "height": "33px",
        "border-radius": "24px",
        "text-align":"center","line-height":"33px",
        "font-size":"28px","font-family":"Roboto","font-weight":"bold",color:"#572C1C",
    },
    ".invite_content_bg":{
        position:"absolute",
        left:"0", top:"408px",width: "100%", "height": "66px",display:"flex","flex-direction":"column","justify-content":"center","align-items":"center",
        ".invite_content":{
            position:"relative",
            top:"0",
            left:"0",
            width: "960px",
            "height": "66px",
            "border-radius": "24px",
            "text-align":"center","line-height":"33px",
            "font-size":"28px","font-family":"Roboto","font-weight":"400",color:"#572C1C",
        },
    },
    ".invite_code_controller":{
        position: "absolute",
        bottom:"68px",
        left: "506px",
        height: "164px",
        "min-width": "484px",
        background: "#FFFFFF",
        display:"flex","flex-direction":"row","justify-content":"space-around","align-items":"center",
        "border-radius": "24px",
        ".invite_code":{
            position: "relative","margin-left":"50px",
            top:"0",
            left: "0",
            height: "52px",
            width: "auto",color:"#000000",
            "font-family":"Roboto","font-size":"44px","font-weight":"bold","line-height":"52px",
        },
        ".invite_code_copy":{
            position: "relative","margin-right":"20px",
            top:"0",
            left: "0",
            height: "32px",
            width:"31px",
            "img":{width:"100%",height:"100%",},
            "&::after":{content:" ",clear:"both"}
        },
    },
}).apply("qrf_invite");