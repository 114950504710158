let qrfDom = require("./include/qrfDom");
let http = require("./include/ContextConf");
require("./css/toastCss");
let studentStatus = require("../common/studentStatus");
qrfDom.addVars({
    showToast:false,
    toastWarningContent:"",
    toastWidth : 248,
});
qrfDom.addEves({
    toast:(content,time)=>{
        console.warn("toast:::"+content);
        if(typeof time == 'undefined' || time == null){//默认500关闭，如果输入小于500，则不起效果，要么点击消失，要么程序关闭消失
            time = 1000;
        }
        qrfDom.set("showToast",true);
        qrfDom.set("toastWarningContent",content);
        let toastWidth = content.length * 18 + 100;
        console.log(toastWidth,content.length);
        qrfDom.set("toastWidth",toastWidth);
        if(time >= 1000){
            setTimeout(function(){
                qrfDom.set("showToast",false);
            },time);
        }
    },
    closeToast:()=>{
        qrfDom.set("showToast",false);
    },
});

qrfDom.definedComp("toastbody",`
<div qrf-if="showToast==true" style="position:absolute;z-index:130;width:100%;height:100%;background: rgba(0,0,0,0.3);">
    <div class="report_toast_warning" qrf-click="closeToast"><div class="toast_content" qrf-style="'width:'+toastWidth+'px;'"><span qrf-text="toastWarningContent"></span></div></div>
</div>`);