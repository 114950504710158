/**
 * Created by 宇 on 2019/5/31.
 */
let qrfDom = require("./qrfDom");
let domainSchool = _process_env.http+"://"+_process_env.url+":"+_process_env.port;
let domainStudyStudent = _process_env.http+"://"+_process_env.url+":"+_process_env.port;
const ContextConf = {
    time:15000,
    data:{
    },
    setHeader:(data,conf)=>{
    },
    prepare:(bindData,conf)=>{
        if(!bindData)return {}
        var params = {
            header:{
                iserial:q_U.iserial(),
                ikey:"qrf",
                module:"report"
            },
            body:{}
        };
        qhttp.extend(params.body,bindData);
        return params;
    },
    bindServerSchema:{
        "endPlan":{
            url:"/study/endStudy",
            time:15000,
            prepare:function(bindData,params){
                console.warn("enter report endPlan");
                return params;
            },
            reqSchema:{
            },
            resSchema:{
            },
            success:(qDom,data,conf)=>{
                if(data.header == null){
                    q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_.notisfyEvent("showMessageBox",{title:"error",content:"back error："+msg});
                        throw msg;
                    }
                    else{
                        //清理本次学习数据
                        q_.setLocalStorage("studyInfo");
                        q_.setLocalStorage("exitTime");
                        q_.setLocalStorage("outAppTime");
                        qrfDom.eves.setCacheSelf(0);
                    }
                }
            },
            error:(err)=>{
                q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                throw err;
            }
        },
        getStudentInfo:{
            url:"/student/getSelfInfo",
            time:15000,
            reqSchema:{
            },
            resSchema:{
            },
            success:(qDom,data,conf)=>{
                if(data.header == null){
                    q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_.notisfyEvent("showMessageBox",{title:"error",content:"back error："+msg});
                        throw data.header.msg;
                    }
                }
                if(null != data.body){//没有登记过信息
                    qrfDom.eves.getStudentInfoSucc(data.body);
                }
            },
            error:(err)=>{
                q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                throw err;
            }
        },
        addCardOrder:{
            url:"/student/addCardOrder",
            time:15000,
            reqSchema:{
                goodName:"goodName"
            },
            resSchema:{
            },
            success:(qDom,data,conf)=>{
                q_.call("#q_loading","close",{});
                if(data.header == null){
                    q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_.notisfyEvent("showMessageBox",{title:"error",content:"back error："+msg});
                        throw data.header.msg;
                    }
                }
                if(null != data.body){//订单生成
                    qrfDom.eves.googlePay(data.body);
                }
            },
            error:(err)=>{
                q_.call("#q_loading","close",{});
                q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                throw err;
            }
        },
        buyCard:{
            url:"/student/buyCard",
            time:15000,
            reqSchema:{
            },
            resSchema:{
            },
            success:(qDom,data,conf)=>{
                q_.call("#q_loading","close",{});
                if(data.header == null){
                    q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_.notisfyEvent("showMessageBox",{title:"error",content:"back error："+msg});
                        throw data.header.msg;
                    }
                }
                if(null != data.body){//没有登记过信息
                    qrfDom.eves.setCardActiveSucc(data.body);
                }
            },
            error:(err)=>{
                q_.call("#q_loading","close",{});
                q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                throw err;
            }
        },
        generateInviteCode:{
            url:"/student/generateInviteCode",
            time:15000,
            reqSchema:{
            },
            resSchema:{
            },
            success:(qDom,data,conf)=>{
                if(data.header == null){
                    q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_.notisfyEvent("showMessageBox",{title:"error",content:"back error："+msg});
                        throw data.header.msg;
                    }
                }
                if(null != data.body){//没有登记过信息
                    qrfDom.set("inviteCode",data.body.inviteCode);
                }
            },
            error:(err)=>{
                q_.notisfyEvent("showMessageBox",{title:"error",content:"back error：An error occurred. Please try again"});
                throw err;
            }
        },
    }
};
const qhttp = new q_Http(ContextConf,qrfDom);
module.exports = qhttp;
