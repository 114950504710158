q_style.writeStyle("qrf_filereport",'.file_report', {
    position:"absolute",
    top:"221px",
    left:"190px",
    width:"1540px",
    height:"700px",
    "background-color":"#FFE9C3",
    "box-shadow": "0px 10px 16px 0px rgba(49, 89, 27, 0.2)",
    "border-radius": "24px",
    ".close_img":{
        position:"absolute",
        top:"52px",
        right:"64px",
        width:"44px",
        height:"44px",
        "img":{
            width:"100%",
            height:"100%",
        }
    },
    ".file_label":{
        position:"absolute",
        top:"52px",
        left:"713px",
        width:"auto",
        height:"40px",
        "text-align":"center","line-height":"40px",
        "font-size":"36px","font-family":"Roboto","font-weight":"bold",color:"#572C1C",
    },
    ".bar_controller":{
        position:"absolute",
        top:"122px",
        left:"64px",
        width:"1412px",
        height:"1px",
        "background-color":"#572D1C",
        opacity: "0.2",
    },
    ".file_remark":{
        position:"absolute",
        top:"165px",
        left:"64px",
        width:"1412px",
        height:"406px",
        "background-color":"#FFFFFF",
        "overflow-y":"auto",
        "text-align":"left","line-height":"30px",
        "font-size":"24px","font-family":"Roboto","font-weight":"bold",color:"#000000",
    },
    ".filesubmit_controller":{
        position: "absolute",
        bottom:"53px",
        left: "64px",
        height: "45px",
        width: "1412px",color:"#FA6400","text-align":"center",
        "font-family":"Roboto","font-size":"32px","font-weight":"bold","line-height":"45px",
    }
}).apply("qrf_filereport");